@charset "utf-8";
/* 시스템 사이트의 경우 :  메인+서브에 공통 적용되는 common.css 파일 추가해 메인+서브 공통부분, 서브에 적용되는 css 기재 /  메인에만 적용되는 main.css 추가해 메인 css 기재 */
/* 일반 사이트의 경우 : 메인+서브에 공통 적용되는 common.css 파일 추가해 메인+서브 공통부분 css / 메인에만 적용되는 main.css 추가해 메인 css 기재 / 서브에만 적용되는 sub.css 추가해 서브 css 기재 */
/* 시스템, 일반 사이트 공통 : 멤버십, 에러 등이 필요한 경우 퍼블 가이드 UI 요소에서 원하는 부분만 복사해서 common.css에 기재 */
/* basic.css, board.css에서 수정해서 사용할 것들은 common.css에 수정 css 적기 (basic, board 수정 X) */

:root{
    --dark: #444;
    --dark-hover: #222;
    --blue: #4359FC;
    --btn-blue-hover : #263DE6;
}
body{font-size: 1.6rem;}

/** layout **/
.content{padding: 8rem 1.6rem 1.6rem;min-height: 100vh;padding-bottom: 10rem;}
.content:has(.tab_menu){padding-top: 10.5rem;}

.btn{height: 4.5rem;}
.btn.dark{background-color: var(--dark); color:#fff; border-color: var(--dark);}
.btn.dark:hover,
.btn.dark:active{background-color: var(--dark-hover); border-color: var(--dark-hover);}
select:focus, input:focus, textarea:focus{border-color: transparent; outline: 2px solid var(--dark);}

/* header */
#header{height: 6rem; /*box-shadow: 0 0 10px rgba(0,0,0,0.16);*/ position: fixed; top: 0; left: 0; width: 100%; background: #fff; display: flex; align-items: center; justify-content: center; z-index: 99;}
#header h2{font-size: 2rem; color: #000; font-weight: 700;}
#header .btn_history_back{font-size: 0; display: block; width: 5.8rem; height: 5.8rem; background-color: #fff; position: absolute; left: 0; top: 0;}
#header .btn_history_back::before{content: "\e93b"; font-family: 'xeicon'; font-size: 2rem;}
#header .btn_del{color: var(--red); background:transparent; font-size: 1.8rem; height: 6rem; width: 8rem; text-align: center; position: absolute; right: 0; top: 0; display: block;}
body:not(:has(.tab_menu)) #header{border-bottom: 1px solid var(--bdr);}
.btn_logout{position: absolute; right: 2rem; top: 2rem; background: #fff; color: #6E7779; font-size: 1.5rem;}


/* fixed_menu */
#fixed_menu{position: fixed;bottom: 0;left: 0;width: 100%;height: 7.5rem;background: #fff;z-index: 99;border-radius: 1.5rem 1.5rem 0 0;box-shadow: 0px -3px 10px rgba(0,0,0,0.16);overflow: hidden;}

div#fixed_menu {}
#fixed_menu ul{display: flex; align-items: center;}
#fixed_menu ul li{flex: 1;}
#fixed_menu ul li a{display: flex;align-items: center;justify-content: center;height: 7.5rem;font-size: 1.4rem;flex-direction: column;background: #fff;color: #CCCCCC;}
#fixed_menu ul li a::before{content: ''; display: block; width: 100%; height: 3.5rem;}
#fixed_menu ul li.on a{color: #5887F7;}
#fixed_menu ul li.menu1 a::before{background: url(../images/icon/i_menu1_off.png) no-repeat center / 3rem;}
#fixed_menu ul li.menu2 a::before{background: url(../images/icon/i_menu2_off.png) no-repeat center / 2.5rem;}
#fixed_menu ul li.menu3 a::before{background: url(../images/icon/i_menu3_off.png) no-repeat center / 3rem;}
#fixed_menu ul li.menu4 a::before{background: url(../images/icon/i_menu4_off.png) no-repeat center / 3rem;}
#fixed_menu ul li.menu1.on a::before{background: url(../images/icon/i_menu1_on.png) no-repeat center / 3rem;}
#fixed_menu ul li.menu2.on a::before{background: url(../images/icon/i_menu2_on.png) no-repeat center / 2.5rem;}
#fixed_menu ul li.menu3.on a::before{background: url(../images/icon/i_menu3_on.png) no-repeat center / 3rem;}
#fixed_menu ul li.menu4.on a::before{background: url(../images/icon/i_menu4_on.png) no-repeat center / 3rem;}

/** input, btns **/
span.chk{display: flex; gap: 0.8rem; flex-wrap: wrap;}
.chk .radio label::before,
.chk .radio label::after{display: none;}
.chk .radio label{font-size: 1.6rem;padding: 1rem 2.5rem;border-radius: 100rem;background: #f2f2f2;}
.chk .radio input:checked ~ label{background: var(--dark);color: #fff;}
.chk > span{margin: 0;}

input[type='text'],
input[type='number'],
input[type='date']{height: 4.5rem;font-size: 1.7rem;}
textarea{font-size: 1.7rem;}
input[type="date"]{-webkit-appearance: none; background: #fff url(../images/icon/i_cal.svg) no-repeat right 1rem center;}

/* custom file */
.filebox{}
.filebox input[type="file"] {overflow: hidden;position: absolute;width: 0;height: 0;padding: 0;border: 0; outline:none !important;}
.filebox ul{padding-top: 1rem;}
.filebox .upload_name {min-width:0;width: auto;text-overflow: ellipsis;overflow: hidden;max-width: calc(100% - 4rem);white-space: nowrap;border: 0;padding: 0;height: auto;display: inline-block; padding-left: 1.1rem; position: relative;}
.filebox .upload_name::before{content: ''; display: block; width: 0.4rem; height: 0.4rem; background: #ddd; border-radius: 100rem; position: absolute; left: 0; top: 1rem;}
.filebox .upload_name + .btn_del{font-size: 0;background: transparent;vertical-align: 5px;}
.filebox .upload_name + .btn_del::before{content: "\e921"; font-family: 'xeicon'; color: var(--red); font-size: 1.5rem;}
.filebox input[type="file"]:focus + .btn,
.filebox input[type="file"]:focus-visible + .btn{outline: 0.2rem solid var(--blue); outline-offset: 0.2rem;}
.filebox .btn{height: 4.5rem;line-height: 4.5rem;width: 100%;}

.btn.sml{font-size: 1.5rem;}


/* count_area */
.count_area{overflow:hidden;}
.count_area *{box-sizing:border-box;}
.count_area input[type=number]{height: 4.5rem;border:0.1rem solid #ddd;float:left;border-left:0;border-right:0;width:5rem;text-align:center;}
.count_area input[type=number]::-webkit-inner-spin-button {appearance: none;-moz-appearance: none;-webkit-appearance: none;}
.count_area .btn_minus,
.count_area .btn_plus{display:inline-block;width: 4.5rem;height: 4.5rem;font-size:2rem;float:left;border:0.1rem solid #ddd;cursor:pointer;}
.count_area .btn_minus{border-radius:0.5rem 0 0 0.5rem;}
.count_area .btn_plus{border-radius:0 0.5rem 0.5rem 0;}

/** contents **/

/* tab */
.tab.basic > .tab_menu{position: fixed;top: 6rem;width: 100%;left: 0;background: #fff;z-index: 99;}
.tab.basic > .tab_menu li{height: 4.5rem; border-top:0}
.tab.basic > .tab_menu li:last-of-type{border-right: 0;}
.tab.basic > .tab_cont{margin-top: 1.6rem;}

/* sub_tab_btns */
.sub_tab_btns{display: flex; flex-wrap: wrap; gap: 0.8rem; margin-bottom: 2rem;}
.sub_tab_btns button{display: inline-block;line-height: 1;padding: 0.8rem 1.5rem;border-radius: 100rem;background: #F3F3F3;font-weight: 400;color: #6E7779;font-size: 1.6rem;}
.sub_tab_btns button.on{background: #334161;color: #fff;}

/* ingredients_list */
.list_top{margin-bottom: 1rem; display: flex; justify-content: space-between; align-items: center;}
.list_top i{margin-right: 0.5rem;}
.list_top b{color: var(--blue);}
.btn_write_float{position: fixed;bottom: 9rem;right: 1.6rem;width: 6rem;height: 6rem;border-radius: 100rem;background: var(--blue);font-size: 0;box-shadow: 2px 2px 5px rgba(0,0,0,0.16); transition: opacity 0.1s;}
.btn_write_float::before{content: "\e913";font-family: 'xeicon';font-size: 2rem;color: #fff;}
.btn_write_float.menu_write::before{content: "\e9c3";}
.btn_write_float.hide{opacity: 0;}
.ingredients_list ul{gap: 1rem;flex-wrap: wrap;display: flex;}
.ingredients_list li{border-radius: 1rem;position: relative;width: calc((100% - 1rem) / 2);/* width: 100%; */border: 1px solid #eee;}
.ingredients_list li a{display: block;padding: 1.2rem;}
.ingredients_list a b{font-weight: 600;display: block;padding-right: 2rem;word-break: break-all;line-height: 1.2;}
.ingredients_list a p{font-size: 1.3rem;color: #777;}
.ingredients_list a b .count{display: inline-block;width: 2rem;height: 2rem;line-height: 2rem;text-align: center;background: #F3F3F3;border-radius: 100rem;color: #7b8585;font-size: 1.2rem;vertical-align: 0.1rem;margin-left: 0.2rem;}
.ingredients_list .btn_del{font-size: 0;width: 3rem;height: 3rem;background: #fff;position: absolute;right: 0.3rem;top: 0.6rem;font-size: 0;text-align: center;}
.ingredients_list .btn_del::before{content: "\e921";font-family: 'xeicon';font-size: 1.6rem;}

/* board_write */
.board_write{border-top: 0;}
.board_write dl{}
.board_write dl ~ *{margin-top: 3rem;}
.board_write dl dt{font-size: 1.8rem;font-weight: 600; position: relative;}
.board_write dl dt .btns{position: absolute; right: 0; top: -0.6rem;}
.board_write dl dt:has(.btns){margin-bottom: 1.2rem;}
.board_write dl dd{font-size: 1.7rem; margin-top: 0.5rem;}
.board_write .col2{display: flex; flex-wrap: wrap; gap: 2rem;}
.board_write .col2 > *{flex: 1;}
.board_write .col2 dl{margin-top: 0 !important;}
.board_write  .input_wrap {position: relative;padding-top: 2rem;border-top: 2px dashed #ddd;}
.board_write  .input_wrap > * + *{margin-top: 1.5rem;}
.board_write  .input_wrap > .btn{position: absolute; right: 0; top: 1.5rem;}
.btn_bottom_area{position: fixed; bottom: 0; left: 0; width: 100%; height: 7.5rem; background-color: #fff; z-index: 999; border-top: 1px solid #ddd; display: flex;}
.btn_bottom_area .btn{flex: 1; border-radius: 0; border: 0; height: 7.5rem; font-size: 1.8rem; font-weight: 500;}
.board_write dl dd + dd{margin-top: 1rem;}
.board_write dl dd .input_btn{display: flex; justify-content: space-between;}
.board_write dl dd .input_btn input{width: calc(100% - 11rem);}

/* board_view */
.board_view{border-top: 0; color: #333;}
.board_view .ingredients_tit{display: block;font-size: 2rem;font-weight: 700;margin-bottom: 2rem;}
.board_view .ingredients_tit .count{display: inline-block;width: 2.5rem;height: 2.5rem;line-height: 2.5rem;text-align: center;background: #F3F3F3;border-radius: 100rem;color: #7b8585;font-size: 1.4rem;vertical-align: 0.1rem;margin-left: 0.2rem;}
.board_view dl{display: flex;padding: 1.2rem;border: 1px solid #eee;border-radius: 1rem;}
.board_view dl + dl{margin-top: 1rem;}
.board_view dl dt{font-size: 1.7rem;font-weight: 600;width: 10rem;position: relative;}
.board_view dl dd{font-size: 1.7rem;width: calc(100% - 10rem);padding-left: 2rem;word-break: break-all;}
.board_view dl.w100p{display: block;}
.board_view dl.w100p dt{width: 100%;}
.board_view dl.w100p dt::after{display: none;}
.board_view dl.w100p dd{width: 100%; padding: 0; margin-top: 1rem;}
.board_view pre{display: block;border-radius: 1rem;line-height: 1.6;word-break: break-all;font-size: 1.6rem;letter-spacing: -0.02em;}
.board_view .link{white-space: nowrap; text-overflow: ellipsis; overflow: hidden; max-width: 100%; display: block; position: relative; padding-right: 2rem;}
.board_view .link::after{content: "\e980"; font-family: 'xeicon'; font-size: 1.6rem; position: absolute; right: 0; top: 0.2rem;}
.btn_recipe_photo{position: absolute; right: 0; top: -0.2rem;}

/* recipe_list */
.recipe_list{}
.recipe_list ul{display: flex;gap: 1.5rem 2rem;flex-wrap: wrap;}
.recipe_list ul li{width: calc((100% - 2rem) / 2);}
.recipe_list ul li a{display: block;}
.recipe_list ul li input{position: absolute; visibility: hidden !important;}
.recipe_list ul li label{display: block;position: relative;/* padding-top: 2rem; */}
.recipe_list ul li label::before{content: '\e928';font-family: 'xeicon';display: inline-block;position: relative;width: 1.7rem;height: 1.7rem;border: 0.9px solid var(--bdr);border-radius: 0.4rem;font-size: 0;font-weight: 900;line-height: 1.4rem;color: var(--bdr);text-align: center;background: #FFFFFF;position: absolute;top: 1rem;left: 1rem;}
.recipe_list ul li input:checked + label::before {border-color: var(--chk-bg);font-size: 1.2rem;color: #FFFFFF;background: var(--chk-bg);}

.recipe_list ul li .img_area{width: 100%; height: 13vh; background: #f2f2f2; overflow: hidden; border-radius: 1rem;}
.recipe_list ul li .img_area img{width: 100%; object-fit: cover; width: 100%; height: 100%;}
.recipe_list ul li b{display: block;text-align: center;font-size: 1.5rem;padding-top: 0.5rem;font-weight: 500;}

/* recipe_view */
.recipe_top{margin-bottom: 3rem;}
.recipe_top .img_area{width: 100%; height: 30vh; background: #f2f2f2; overflow: hidden; border-radius: 1rem;}
.recipe_top .img_area img{width: 100%; object-fit: cover; width: 100%; height: 100%;}
.recipe_top b{display: block;text-align: center;font-size: 2.5rem;padding-top: 1rem;font-weight: 600;word-break: break-all;}
.recipe_top .btn_favorite{font-size: 0; background: transparent; display: inline-block; vertical-align: -0.2rem;}
.recipe_top .btn_favorite i{font-size: 2.5rem; color: #ddd;}
.recipe_top .btn_favorite.on i{color: #dbac28;}

/* top_sch */
.top_sch{position: relative; margin-bottom: 1rem;}
.top_sch input{padding-right: 6rem;width: 100%;border-radius: 100rem;background: #f2f2f2;border: 0;padding-left: 2rem;}
.top_sch .btn_sch{display: block;width: 5rem;height: 4.5rem;background: transparent;position: absolute;right: 0.5rem;top: 0;font-size: 0;}
.top_sch .btn_sch i{font-size: 2.4rem;}

/* layer_popup */
.layer_pop .pop_content{max-height: 70vh; overflow-y: auto;}
.recipe_img img{width: 100%;}

/* comment_area */
.comment_area{margin-top: 6rem; padding: 1.5rem; background: #f1f1f1; border-radius: 1rem; position: relative;}
.comment_area > p{position: absolute; top: -3.5rem; left: 0; font-size: 1.8rem; font-weight: 600;}
.comment_area .comment_write{display: flex; align-items: center; justify-content: space-between;}
.comment_area .comment_write textarea{height: 10rem; min-height: 0; width: calc(100% - 11rem);}
.comment_area .comment_write .btn{width: 10rem; height: 10rem; min-width: 0;}
.comment_area .comment_list{margin-top: 2rem;}
.comment_area .comment_list li{position: relative;background: #fff;padding: 1rem;padding-right: 7rem;border-radius: 1rem;}
.comment_area .comment_list li + li{margin-top: 1rem;}
.comment_area .comment_list li p{font-size: 1.4rem; color: #777;}
.comment_area .comment_list li .btn_del{position: absolute; right: 1rem; top: 1rem;}

/* login */
.login_wrap{min-height: 100vh;}
.login_box h1.logo{margin-bottom: 2rem;}
.login_box h1.logo img{max-width: 15rem;}
.login_box .input_box > ul > li label{position: absolute; font-size: 1.4rem; left: 1.6rem; top:1rem; font-weight: 600;}
.login_box .input_box > ul > li input{height: 7rem; padding-top: 2rem; font-weight: 500; font-size: 1.8rem; border-radius: 1rem;}
.login_box .input_box > ul > li input:focus{outline: 2px solid black; border-color: transparent;}
.btn_login{height: 5.6rem !important;}

/* tab */
.tab.basic > .tab_menu {border-bottom: 1px solid var(--bdr);}
.tab.basic > .tab_menu li{background-color: transparent; border: 0; position: relative;}
.tab.basic > .tab_menu li a{color: #222;font-size: 1.8rem;padding-bottom: 1.5rem;}
.tab.basic > .tab_menu li a.on{color: var(--blue);}
.tab.basic > .tab_menu li:has(.on){background-color: transparent; font-weight: 700;}
.tab.basic > .tab_menu li:has(.on)::after{content: '';display: block;width: 100%;height: 0.3rem;background: var(--blue);position: absolute;left: 0;bottom: 0;}

/* buy_list */
.content:has(.top_write){padding-top: 17.5rem;}
.top_write{display: flex;align-items: center;justify-content: space-between;background: #f2f2f2;width: 100%;padding: 1.6rem;position: fixed;left: 0;top: 10rem;z-index: 9;}
.top_write input{width: calc(100% - 10rem);}
.top_write .btn{}
.buy_list{margin-top: 2rem;}
.buy_list li{border: 1px solid #eee; border-radius: 1rem; position: relative;}
.buy_list li .btn{position: absolute;right: 0.8rem;top: 0.7rem;}
.buy_list li + li{margin-top: 1rem;}
.buy_list li .chk,
.buy_list li .cbx,
.buy_list li label{display: block;}
.buy_list li label{padding: 1.5rem;padding-left:3.8rem;padding-right: 8rem;word-break: break-all;}
.buy_list li input:checked ~ label{text-decoration: line-through;}
.buy_list li label::before{position: absolute !important; left: 1.2rem;}

/* menu_list*/
.content:has(.menu_date_range){padding-top: 13rem;}
.menu_date_range{display: flex; justify-content: space-between; align-items: center; width: 100%; background-color: #fff;  position: fixed; top: 6rem; left: 0; padding: 1.6rem;}
.menu_date_range p{font-size: 1.8rem; font-weight: 600;}
.menu_date_range button{display: block; width: 3.5rem; height: 3.5rem; background: #fff; font-size: 0; border-radius: 100rem; border: 1px solid #ddd;}
.menu_date_range button::before{content: "\e93b"; font-family: 'xeicon'; font-size: 1.7rem;}
.menu_date_range button.btn_next{transform: rotate(180deg);}
.menu_list{}
.menu_list > ul{}
.menu_list > ul > li{padding: 1.2rem;border-radius: 1rem;display: flex;align-items: center;background: #F3F3F3;}
.menu_list > ul > li + li{margin-top: 1rem;}
.menu_list > ul > li .date{display: flex;flex-direction: column;align-items: center;justify-content: center;background: #fff;width: 8rem;padding: 1rem 1rem;border-radius: 1rem;line-height: 1.2;}
.menu_list > ul > li ul{width: calc(100% - 8rem); padding-left: 1rem;}
.menu_list > ul li li{font-size: 1.7rem; font-weight: 500;}
.menu_list > ul li li a::after{content: "\e907"; font-family: 'xeicon'; display: inline-block; width: 2.4rem; height: 2.4rem; border-radius: 100rem; background-color: var(--blue); color: #fff; font-size: 1.4rem; text-align: center; line-height: 2.4rem; margin-left: 0.5rem;}