@charset "UTF-8";
/* 브라우저 기본 스타일 리셋 + 기본 class 선언 */

*, ::before, ::after {box-sizing: border-box;-webkit-box-sizing: border-box;}
html {height: 100%;font-size: 16px;-webkit-text-size-adjust: 100%;scroll-behavior: smooth;}
body {width: 100%;font-family: "Pretendard", sans-serif;font-size: 1rem;line-height: 1.45;color: #333;letter-spacing: -0.03em;word-break: keep-all;}
a {color: inherit;text-decoration: none;}
body, h1, h2, h3, h4, h5, h6, ul, dl, ol, div, p, figure {margin: 0;}
h1, h2, h3, h4, h5, h6 {font-weight: normal;color: inherit;}
ul, dl, ol {padding: 0;}
dd {margin-left: auto;}
li {list-style: none;}
form {width: 100%;margin: auto;}
fieldset {margin: auto;padding: 0;border: 0;}
legend {display: none;}
input:focus, button:focus, textarea:focus, select:focus {outline-width: 0;}
select {border-color: transparent;}
select::-ms-expand {display: none;}
button {padding: 0;border-color: transparent;cursor: pointer;}
button, textarea, pre, code {font-family: inherit;font-size: inherit;}
pre {white-space: pre-wrap;}
pre, code {margin: 0;padding: 0;}
textarea {overflow: auto;resize: none;}
i, em {font-style: normal;}
caption {display: none;}
table {width: 100%;border-spacing: 0;border-collapse: collapse;table-layout: fixed;empty-cells: show;}
th, td {background-clip: padding-box !important;}
address {font-style: normal;}

/** basic **/
.hidden {visibility: hidden;position: absolute;left: -9000px;top: -9000px;}
.display_none {display: none;}
.cursor {cursor: pointer;}

/** border **/
.no_bdl {border-left: 0 !important;}
.no_bdr {border-right: 0 !important;}
.no_bdt {border-top: 0 !important;}
.no_bdb {border-bottom: 0 !important;}

/** align **/
.l {text-align: left !important;}
.c {text-align: center !important;}
.r {text-align: right !important;}
.t {vertical-align: top !important;}
.m {vertical-align: middle !important;}
.b {vertical-align: bottom !important;}
.fl {float: left !important;}
.fr {float: right !important;}

/** clear **/
.clear::after {content: '';display: block;clear: both;}

/*** form ***/
form {margin: 0;}
fieldset {margin: 0;padding: 0;border: none;}
legend {display: none;}
input, select, button, a.btn {vertical-align: middle;}
textarea:focus, button:focus, a.btn:focus, input:focus {outline: none;}

/** display **/
.disinb {display: inline-block;}
.disin {display: inline;}
.disb {display: block;}