@charset "UTF-8";
@import 'root.css';
@import 'reset.css';
@import url('../lib/xeicon/xeicon.min.css');
@import url('../fonts/pretendard/pretendard-gov-subset.css');

/***** mobile_basic *****/

html {font-size: 10px;}
@media screen and (max-width: 1024px) { 
    /* 태블릿 버전 스타일 (모바일~태블릿 공통 스타일) */ 
    html {font-size: 12px;}
}
@media screen and (max-width: 767px) { 
    /* 모바일 버전 스타일 (태블릿 대비 모바일에서 달라지는 스타일) */
    html {font-size: 11px;}
}
@media screen and (max-width: 480px) { 
    /* 모바일 버전 스타일2 (모바일 작은 화면에서 달라지는 스타일) */
    html {font-size: 10px;}
}

/*** button ***/
.btn_area {margin-top: 2.2rem;text-align: right;}
.btn_area.left {text-align: left;}
.btn_area.center {text-align: center;}
.btn_area .btn + .btn {margin-left: 0.3rem;}

/* button size */
.btn {display: inline-block;min-width: 8.9rem;height: 4rem;margin: 0;padding: 0 1.3rem;border: 0.9px solid var(--dark);border-radius: 0.6rem;font-size: 1.6rem;line-height: 3.8rem;color: var(--dark);text-align: center;letter-spacing: -0.05em;background-color: transparent;transition: 0.1s color, 0.1s border-color, 0.1s background-color;}
.btn:hover, .btn:focus {background-color: var(--btn-hover);}
.btn.sml {min-width: 5.1rem;height: 3.3rem;padding: 0 1.1rem;font-size: 1.3rem;line-height: 3.1rem;}
.btn_sns {min-width: 11.1rem;height: 3.7rem;padding: 0 0.9rem;font-size: 1.4rem;line-height: 3.4rem;}

/* only icon button */
.btn.ic {min-width: 3.3rem;width: 3.3rem;height: 3.3rem;padding: 0;border: 0.9px solid var(--bdr);border-radius: 0.4rem;font-family: 'xeicon';font-size: 1.8rem;line-height: 3.1rem;color: var(--dark);letter-spacing: 0;}
.btn.ic:hover, .btn.ic:focus {background-color: var(--basic-bg);}
.btn.ic i {bottom: 0;}
.btn.ic + .btn.ic {margin-left: 0.6rem;}

/* set button color */
/* blue */
.btn.blue, .btn.btn_save, .btn.btn_write, .btn.btn_rewrite {border-color: var(--blue);color: #FFFFFF;background-color: var(--blue);}
.btn.blue:hover, .btn.btn_save:hover, .btn.btn_write:hover, .btn.btn_rewrite:hover,
.btn.blue:focus, .btn.btn_save:focus, .btn.btn_write:focus, .btn.btn_rewrite:focus {border-color: var(--btn-blue-hover);background-color: var(--btn-blue-hover);}
/* red */
.btn.red, .btn.btn_del {border-color: var(--red);color: #FFFFFF;background-color: var(--red);}
.btn.red:hover, .btn.btn_del:hover,
.btn.red:focus, .btn.btn_del:focus {border-color: var(--btn-red-hover);background-color: var(--btn-red-hover)}
/* green */
.btn.green {border-color: var(--green);color: #FFFFFF;background-color: var(--green);}
.btn.green:hover, .btn.green:focus {border-color: var(--btn-green-hover);background-color: var(--btn-green-hover);}
/* gray */
.btn.gray, .btn.btn_cancel, .btn.btn_list {border-color: var(--gray);color: #FFFFFF;background-color: var(--gray);}
.btn.gray:hover, .btn.btn_cancel:hover, .btn.btn_list:hover,
.btn.gray:focus, .btn.btn_cancel:focus, .btn.btn_list:focus {border-color: var(--btn-gray-hover);background-color: var(--btn-gray-hover);}
/* gray light */
.btn.gray.light {border-color: var(--gray-light);color: var(--dark);background-color: var(--gray-light);}
.btn.gray.light:hover, .btn.gray.light:focus {border-color: var(--btn-gray-light-hover);background-color: var(--btn-gray-light-hover);}
/* dark */
.btn.black {color: #FFFFFF;background-color: var(--black);}
.btn.black:hover, .btn.black:focus {background-color: var(--dark);}

/* function button */
.btn.btn_excel {padding-left: 3.6rem;background: url(../images/icon/file_excel.svg) no-repeat 1.1rem 50% / auto 1.8rem;}
.btn.btn_excel:hover, .btn.btn_excel:focus {background-color: var(--btn-hover);}

/* button border */
.btn.bd {background-color: transparent;}
.btn.bd.red {border-color: var(--red);color: var(--red);}
.btn.bd.red:hover, .btn.bd.red:focus {background-color: var(--btn-bd-red-hover);}
.btn.bd.green {border-color: var(--green);color: var(--green);}
.btn.bd.green:hover, .btn.bd.green:focus {background-color: var(--btn-bd-green-hover);}
.btn.bd.blue {border-color: var(--blue);color: var(--blue);}
.btn.bd.blue:hover, .btn.bd.blue:focus {background-color: var(--btn-bd-blue-hover);}
.btn.bd.gray {border-color: var(--gray);color: var(--gray);}
.btn.bd.gray:hover, .btn.bd.gray:focus {background-color: var(--btn-bd-gray-hover);}

/* icon button */
.btn i {position: relative;bottom: -0.9px;font-size: 1.7rem;color: inherit;}
.btn.sml i {font-size: 1.4rem;}
.btn.icl i {margin-right: 0.3rem;}
.btn.icr i {margin-left: 0.3rem;}
.btn.sml.icl, .btn.sml.icr {padding: 0 1.1rem;}

/* disabled */
.btn:disabled, .btn.disabled {border: 0.9px solid var(--btn-disabled-bg) !important;color: var(--btn-disabled-font) !important;background-color: var(--btn-disabled-bg) !important;cursor: default !important;}
.btn.bd:disabled, .btn.bd.disabled {border: 0.9px solid var(--btn-bd-disabled) !important;color: var(--btn-bd-disabled) !important;background-color: transparent !important;cursor: default !important;}


/** tag **/
.state {display: inline-block;min-width: 5.6rem;height: 2.7rem;padding: 0 1.1rem;border-radius: 0.4rem;font-size: 1.3rem;font-weight: 400;line-height: 2.7rem;color: #FFFFFF;letter-spacing: -0.03em;text-align: center;}
.state.red {background-color: var(--tag-red);}
.state.green {background-color: var(--tag-green);}
.state.blue {background-color: var(--tag-blue);}
.state.gray {background-color: var(--tag-gray);}
.state.black {background-color: var(--tag-black);}


/*** form ***/
/* select, input, textarea */
select,
input[type="text"],
input[type="password"],
input[type="date"],
textarea {width: auto;max-width: 100%;height: 4rem;border: 0.9px solid var(--bdr);border-radius: 0.6rem;font-family: inherit;font-size: 1.6rem;line-height: inherit;color: var(--dark);letter-spacing: -0rem;transition: 0.1s color, 0.1s border-color;}
select::placeholder,
input::placeholder,
textarea::placeholder {color: var(--plholder-font);}
select:focus,
input:focus,
textarea:focus {border: 0.9px solid var(--bdr-focus);transition: color .1s border-color .1s;}
input:focus::placeholder,
textarea:focus::placeholder {color: var(--plholder-focus-font);}
select {min-width: 14.4rem;padding: 0 3.6rem 0 1.3rem;background: #fff url(../images/icon/i_sel.png) no-repeat 100% 50%;appearance: none;}	
select:not(:disabled) {cursor: pointer;}
input[type="text"],
input[type="password"],
input[type="date"] {padding: 0 1.3rem;}
input[type="password"] {letter-spacing: 0;}
input[readonly],
textarea[readonly] {background-color: var(--disabled-bg);}
select[disabled],
input[disabled],
textarea[disabled] {color: var(--disabled-font);background-color: var(--disabled-bg);}
select[readonly] {background: #eaeaea url(../images/icon/i_sel.png) no-repeat 100% 50%;}
textarea {display: block;width: 100%;min-height: 16.7rem;height: auto;padding: 0.9rem 1.3rem;}
select.auto,
input.auto {width: auto !important;}
select.err,
input[type="text"].err,
input[type="password"].err,
textarea.err {border: 0.9px solid var(--err-bdr);color: var(--err-font);background-color: var(--err-bg);}
select.err::placeholder,
input[type="text"].err::placeholder,
input[type="password"].err::placeholder,
textarea.err::placeholder {color: var(--err-bdr);}

/* custom select */
.selectbox {display: inline-block;position: relative;}
.selectbox .selected {display: inline-block;min-width: 14.4rem;width: auto;max-width: 100%;height: 4rem;padding: 0 3.6rem 0 1.3rem;border: 0.9px solid var(--bdr);border-radius: 0.6rem;font-family: inherit;font-size: 1.6rem;line-height: 3.8rem;color: var(--dark);letter-spacing: -0rem;background: #fff;transition: 0.1s color, 0.1s border-color;}
.selectbox .selected::after {content: '';display: block;position: absolute;right: 1.2rem;top: 1.8rem;width: 1rem;height: 0.6rem;background: url(../images/icon/i_sel2.png) no-repeat center;transition: all 0.1s;}
.selectbox.on .selected::after {transform: rotate(180deg);}
.selectbox.on .selected {border-color: var(--bdr-focus);}
.selectbox .selectoptions {display: none;position: absolute;left: 0;top: 3.6rem;width: 100%;padding: 0.6rem 0 0.3rem;border: 0.9px solid var(--bdr-focus);border-top: 0;border-radius: 0rem 0rem 0.6rem 0.6rem;background: #fff;z-index: 99;}
.selectbox .selectoptions .option a {display: block;width: 100%;height: 100%;padding: 0.7rem 1.3rem;font-size: 1.6rem;color: var(--dark);}
.selectbox .selectoptions .option a:hover, .selectbox .selectoptions .option.on {font-weight: bold;text-decoration: underline;}
.selectbox.disabled .selected {opacity: 0.7;color: var(--disabled-font);background-color: var(--disabled-bg);}
.selectbox.err .selected, .selectbox.err .selectoptions {border: 0.9px solid var(--err-bdr);color: var(--err-font);background-color: var(--err-bg);}
.selectbox.err .selectoptions .option a {color: var(--err-font);}
.selectbox.err .selectoptions {border-top: 0;}

/* chk */
span.chk {display: inline-block;}
div.chk + div.chk {margin-top: 0.6rem;}
.chk input {opacity: 0;position: absolute;}
.chk > span {display: inline-block;margin: 0.3rem 1.7rem 0.3rem 0;}
.chk > span:last-child {margin-right: 0;}
.chk > span label {display: inline-block;position: relative;line-height: 1;cursor: pointer;}
.chk label::before {margin-right: 0.9rem;vertical-align: bottom;}
.chk .no_txt label::before {margin-right: 0;}
table .chk label::before {top: 0.9px;}
/* chk checkbox */
.chk .cbx label::before {content: '\e928';font-family: 'xeicon';display: inline-block;position: relative;width: 1.7rem;height: 1.7rem;border: 0.9px solid var(--bdr);border-radius: 0.4rem;font-size: 0;font-weight: 900;line-height: 1.4rem;color: var(--bdr);text-align: center;background: #FFFFFF;}
.chk .cbx label:hover::before {border-color: var(--gray);}
.chk .cbx input:focus + label::before {border-color: var(--bdr-focus);}
.chk .cbx input:checked + label::before {border-color: var(--chk-bg);font-size: 1.2rem;color: #FFFFFF;background: var(--chk-bg);}
.chk .cbx input:disabled + label {color: var(--bdr);cursor: auto;}
.chk .cbx input:disabled + label::before {border-color: var(--bdr);}
.chk .cbx input:disabled[checked] + label::before {background: var(--disabled-bg);border-color: var(--disabled-bg);}
.chk .cbx input.err + label::before {border-color: var(--err-bdr);}
.chk .cbx input.err + label {color: var(--err-font);}
/* chk radio */
.chk .radio label::before {content: '';display: inline-block;position: relative;width: 1.7rem;height: 1.7rem;border: 0.9px solid var(--bdr);border-radius: 50%;background: #FFFFFF;}
.chk .radio label::after {content: '';position: absolute;left: 0.4rem;top: 0.6rem;width: 0.8rem;height: 0.8rem;border-radius: 50%;}
.chk .radio label:hover::before {border-color: var(--gray);}
.chk .radio input:active + label::before {border-color: var(--bdr-focus);}
.chk .radio input:checked + label::before {border-color: var(--chk-bg);background: var(--chk-bg);}
.chk .radio input:checked + label::after {background: #FFFFFF;}
.chk .radio input:disabled + label {color: var(--bdr);cursor: auto;}
.chk .radio input:disabled + label::before {border-color: var(--bdr);}
.chk .radio input:disabled + label::after {background: #FFFFFF;}
.chk .radio input:disabled[checked] + label::before {background: var(--bdr);}
.chk .radio input.err + label::before {border-color: var(--err-bdr);}
.chk .radio input.err + label {color: var(--err-font);}
/* on/off */
.chk .tgl label {font-size: 1.8rem;color: var(--dark);}
.chk .tgl label::before {content: '';display: inline-block;position: relative;width: 3.3rem;height: 1.7rem;border-radius: 0.9rem;background: var(--bdr);}
.chk .tgl label::after {content: '';position: absolute;left: 0.3rem;top: 0.4rem;width: 1rem;height: 1rem;border-radius: 0.6rem;background: #FFFFFF;transition: 0.3s ease;}
.chk .tgl label:hover::before,
.chk .tgl input:focus + label::before {background: var(--plholder-font);}
.chk .tgl input:checked + label::before {background: var(--chk-bg);}
.chk .tgl input:checked + label::after {left: 2rem;}
.chk .tgl input:disabled + label {color: var(--bdr);cursor: auto;}
.chk .tgl input:disabled + label::before {background: var(--disabled-bg);}
.chk .tgl input.err + label {color: var(--err-font);}
.chk .tgl input.err + label::before {background: var(--err-bdr);}



/* calendar */
.calendar_input {display: inline-block;position: relative;width: 14.4rem;}
.calendar_input input {min-width: 14.4rem;width: 100%;padding-right: 4.2rem;background: #fff;}
.calendar_input .ui-datepicker-trigger {position: absolute;right: 0.9rem;top: 0.9rem;width: 2.2rem;height: 2.2rem;background: url(../images/icon/i_cal.svg) no-repeat center / 2.2rem;cursor: pointer;}
/* l/r side, gap(~) */
.lside,
.rside {font-size: 1.6rem;color: var(--dark);letter-spacing: -0.1rem;}
.lside {margin-right: 0.6rem;}
.rside {margin-left: 0.6rem;}
.gap {margin: 0 0.6rem;vertical-align: middle;}
/* field */
.field {word-break: break-word;}
.field + .field {margin-top: 0.8rem;}
th .field {padding: 0;}
/* interval */
td .btn + .btn {margin-left: 0.3rem;}
td > *:not(.gap):not([class$="_txt"]):not(div) ~ *:not(.gap):not([class$="_txt"]):not(div),
.field > *:not(.gap):not([class$="_txt"]):not(div) ~ *:not(.gap):not([class$="_txt"]):not(div) {margin-left: 0.6rem;}
td > .gap + *:not(.gap) {margin-left: 0 !important;}
td .chk + input:not([type="hidden"]),
td .chk + select {margin-left: 1.1rem;}
/* btns */
td.btns {padding: 0.6rem 1.1rem;}
.btns .btn {margin: 0.3rem 0.3rem 0.3rem 0 !important;}
.btns .btn:last-child {margin-right: 0 !important;}
/* phone country */
.country {display: flex;}
.country > * {min-width: auto;width: calc((100% - 2.9rem) / 3) !important;max-width: 14.8rem !important;}
.country > span {margin: 0 1.4rem;line-height: 3.6rem;text-align: center;}
/* phone */
.phone {display: flex;align-items: center;}
.phone input, .phone select {min-width: 0;width: 33.333% !important;}
/* addr */
.addr .btn {margin: 0 0 0 0.6rem;padding: 0 1.1rem;}
.addr input:first-of-type {width: 14.4rem !important;margin: 0;}
.addr input {margin-top: 0.6rem;}
/* email */
.email {display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;}
.email input, .email select {min-width: 0;width: calc(50% - 1.7rem) !important;}
.email input:last-of-type {width: 100% !important;margin-left: 0;margin-top: 0.6rem;}

/* custom file */
.filebox input[type="file"] {overflow: hidden;position: absolute;width: 0;height: 0;padding: 0;border: 0;}
.filebox .upload_name {min-width: 33.3rem;width: auto;max-width: 100%;height: 4rem;padding: 0 1.3rem;border: 0.9px solid var(--bdr);border-radius: 0.6rem;font-family: inherit;font-size: 1.6rem;line-height: inherit;color: var(--dark);letter-spacing: -0rem;transition: 0.1s color, 0.1s border-color;}

/* file */
.file_wrap {position: relative;text-align: left;}
.file_wrap .btn {display: inline-block;position: relative;height: 3rem;border-radius: 0.4rem;font-size: 1.4rem;line-height: 2.8rem;text-align: center;cursor: pointer;}
.file_wrap .btn .fa_check {padding-left: 1.8rem;background: url(../images/icon/fa_check.svg) no-repeat 0 50%;}
.file_wrap .btn .fa_remove {padding-left: 1.8rem;background: url(../images/icon/fa_remove.svg) no-repeat 0 50%;}
.file_wrap .file_tit {position: relative;padding-right: 7.8rem;text-align: left;}
.file_wrap .file_tit span img {margin-right: 0.6rem;vertical-align: sub;}
.file_dragarea {overflow: hidden;position: relative;height: 14.9rem;margin-top: 1.1rem;border: 0.9px solid var(--tbl-bdr);background: #FDFDFD url(../images/icon/bg_drag_image.png) no-repeat 50% 50%;}
.file_txt {position: absolute;font-size: 0;}
.tbl_file {margin-top: 1.1rem;border-top: 0.9px solid var(--tbl-bdr);}
.tbl_file thead th {border-right: 0 !important;background: var(--tbl-th) !important;}
.tbl_file td {border-right: 0 !important;}
.tbl_file th::after {display: none !important;}

/** calendar **/
.ui-datepicker-trigger {margin-left: 0.3rem;}
#ui-datepicker-div .ui-widget-header {border: 0 !important;background: none !important;}
#ui-datepicker-div .ui-datepicker-title .m {color: #000 !important;}
#ui-datepicker-div.ui-datepicker select.ui-datepicker-month,
#ui-datepicker-div.ui-datepicker select.ui-datepicker-year {min-width: 7.8rem;width: auto !important;}
#ui-datepicker-div.ui-datepicker td {padding: 0 !important;border: 0.9px solid #eaeaea !important;background: #FFFFFF;}
#ui-datepicker-div.ui-datepicker td span,
#ui-datepicker-div.ui-datepicker td a {padding: 4.5px !important;}
#ui-datepicker-div .ui-state-default,
#ui-datepicker-div.ui-widget-content .ui-state-default,
#ui-datepicker-div .ui-widget-header .ui-state-default,
#ui-datepicker-div .ui-button,
html #ui-datepicker-div .ui-button.ui-state-disabled:hover,
html #ui-datepicker-div .ui-button.ui-state-disabled:active {border: 0;}
#ui-datepicker-div.ui-widget.ui-widget-content {width: 28.9rem;border: none;background: #eaeaea;}
#ui-datepicker-div .ui-state-default,
#ui-datepicker-div .ui-widget-content .ui-state-default,
#ui-datepicker-div .ui-widget-header .ui-state-default,
#ui-datepicker-div .ui-button,
html #ui-datepicker-div .ui-button.ui-state-disabled:hover,
html #ui-datepicker-div .ui-button.ui-state-disabled:active {border: 0 !important;line-height: 1;text-align: center;background: none !important;}
#ui-datepicker-div .ui-widget-header .ui-state-hover,
#ui-datepicker-div .ui-widget-header .ui-corner-all:focus {top: 0.4rem;border-color: #ccc;background: transparent;}
.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-prev {left: 0.7rem;}
.ui-datepicker .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-next {right: 0.7rem;}
#ui-datepicker-div .ui-widget-header .ui-corner-all {cursor: pointer;}
#ui-datepicker-div .ui-widget-header .ui-datepicker-prev .ui-icon {background: url(../images/icon/i_cal_prev.png) no-repeat 50% 50%;}
#ui-datepicker-div .ui-widget-header .ui-datepicker-next .ui-icon {background: url(../images/icon/i_cal_next.png) no-repeat 50% 50%;}
#ui-datepicker-div .ui-state-highlight,
#ui-datepicker-div.ui-widget-content .ui-state-highlight,
#ui-datepicker-div .ui-widget-header .ui-state-highlight {font-weight: bold !important;color: #007fff !important;}
#ui-datepicker-div .ui-state-hover,
#ui-datepicker-div.ui-widget-content .ui-state-hover,
#ui-datepicker-div .ui-widget-header .ui-state-hover,
#ui-datepicker-div .ui-state-focus,
#ui-datepicker-div.ui-widget-content .ui-state-focus,
#ui-datepicker-div .ui-widget-header .ui-state-focus,
#ui-datepicker-div .ui-button:hover,
#ui-datepicker-div .ui-button:focus {color: #007fff !important;}
#ui-datepicker-div .ui-state-active,
#ui-datepicker-div.ui-widget-content .ui-state-active,
#ui-datepicker-div .ui-widget-header .ui-state-active,
#ui-datepicker-div a.ui-button:active,
#ui-datepicker-div .ui-button:active,
#ui-datepicker-div .ui-button.ui-state-active:hover {color: #fff !important;background: #007fff !important;}
.ui-widget select {color: #222;}

/***** //mobile_basic *****/


/*** table ***/
table .no_data {text-align: center !important;}
table tr:hover .no_data {background: inherit !important;} 
table .asterisk {margin-left: -1.1rem;}
td img {vertical-align: middle;}
.ellipsis {overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
table a.ellipsis {display: inline-block;max-width: 95%;vertical-align: middle;}
table a.ellipsis + i {vertical-align: middle;}

/* asterisk(*) + form guide */
.asterisk {margin-right: 0.3rem;font-size: 1.6rem;font-weight: 400;color: var(--red);}
.form_guide {margin-bottom: 1.1rem;font-size: 1.6rem;font-weight: 400;text-align: right;}

/* table style */
.data_tbl {border-top: 0.9px solid var(--black);}
.data_tbl > * > tr > th,
.data_tbl > * > tr > td {position: relative;height: 4.4rem;padding: 0.7rem 0.9rem;border-bottom: 0.9px solid var(--tbl-bdr);font-size: 1.6rem;font-weight: 400;color: var(--dark);letter-spacing: -0.05em;text-align: center;background: #FFFFFF;}
.data_tbl > * > tr > th {font-weight: 500;background: var(--tbl-th);}
.data_tbl > * > tr > *:not(:last-child) {border-right: 0.9px solid var(--tbl-bdr);}
.data_tbl > tbody > tr > td + td[rowspan] {border-left: 0.9px solid var(--tbl-bdr);}
.data_tbl:not(.form) > tbody > tr:hover > td:not(.no_data) {background: var(--form-tbl-hover-bg);}

/* table border */
.data_tbl .bdt {border-top: 0.9px solid var(--tbl-bdr);}
.data_tbl .bdr {border-right: 0.9px solid var(--tbl-bdr);}
.data_tbl .bdb {border-bottom: 0.9px solid var(--tbl-bdr);}
.data_tbl .bdl {border-left: 0.9px solid var(--tbl-bdr);}

/* table form */
.data_tbl.form > * > tr > th,
.data_tbl.form > * > tr > td {height: 5.4rem;}
.data_tbl.form input[type="text"], .data_tbl.form input[type="password"], .data_tbl.form select {width: 100%;}
.data_tbl.form .btn {min-width: 5.6rem;}

/* scroll table */
.tbl_scroll {position: relative;}
.tbl_scroll tbody + tfoot tr:first-child td {border-top: 0.9px solid var(--tbl-bdr);}

/* horizontal scroll table */
.tbl_scroll.scr_h {overflow-x: auto;position: relative;}

/** board **/
/* board list count */
.all_num {margin-bottom: 1.1rem;font-size: 1.4rem;color: #666;}
.all_num > * {vertical-align: bottom;}
.all_num i::before {position: relative;top: -0.1rem;margin-right: 0.3rem;font-size: 1.7rem;}
.all_num .num {margin: 0 0.3rem 0 0.6rem;font-weight: 400;color: #26a4de;}

/* board list */
.board_list {border-top: 0.9px solid var(--black);}
.board_list ul li {border-bottom: 0.9px solid var(--tbl-bdr);color: var(--dark);letter-spacing: -0.05em;}
.board_list ul li a {display: block;position: relative;padding: 1.1rem 0.9rem;padding-left: 4.4rem;}
.board_list ul li .num {position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 4.4rem;color: var(--dark);text-align: center;}
.board_list ul li b {font-size: 1.6rem;font-weight: normal;line-height: 1.4;}
.board_list ul li a > div {padding-top: 0.3rem;}
.board_list ul li span {font-size: 1.4rem;}
.board_list ul li span + span {margin-left: 1.1rem;}
.board_list ul li.no_data {padding: 2.2rem 0.9rem;font-size: 1.6rem;text-align: center;}

/* board view */
.board_view {border-top: 0.9px solid var(--black);color: var(--dark);letter-spacing: -0.05em;}
.board_view .title {padding: 1.1rem 0.9rem;border-bottom: 0.9px solid var(--tbl-bdr);}
.board_view .title .tit {display: block;margin-bottom: 0.6rem;font-size: 1.7rem;font-weight: 500;line-height: 1.4;}
.board_view .title span {font-size: 1.6rem;}
.board_view .title span + span {margin-left: 1.1rem;}
.board_view .content {padding: 0.9rem;min-height: 22.2rem;border-bottom: 0.9px solid var(--tbl-bdr);font-size: 1.6rem;}
.board_view .file {padding: 1.1rem 0.9rem;border-bottom: 0.9px solid var(--tbl-bdr);}
.board_view .file b {display: block;margin-bottom: 0.6rem;font-size: 1.6rem;font-weight: 500;}
.board_view .file_li li a {display: block;position: relative;padding-left: 2.2rem;font-size: 1.6rem;}
.board_view .file_li li + li {margin-top: 0.3rem;}
.board_view .file_li li img {position: absolute;left: 0;top: 0.1rem;width: 1.7rem;}
.board_view .detail {padding-top: 0.9rem;}

/* board write */
.board_write {border-top: 0.9px solid var(--black);}
.board_write > * > tr > th,
.board_write > * > tr > td {position: relative;height: 5.4rem;padding: 0.7rem 0.9rem;border-bottom: 0.9px solid var(--tbl-bdr);font-size: 1.6rem;font-weight: 400;color: var(--dark);letter-spacing: -0.05em;text-align: left;background: #FFFFFF;}
.board_write > * > tr > th {height: 4.4rem;padding: 0.9rem 1.1rem 0.9rem 2.8rem;font-weight: 500;background: var(--tbl-th);}
.board_write input[type="text"], .board_write input[type="password"], .board_write select, .board_write input[type="date"] {width: 100%;}
.board_write .btn {min-width: 5.6rem;}

/* board link prev/next post */
.oth_post {margin-top: 2.2rem;border-top: 0.9px solid var(--tbl-bdr);}
.oth_post th, .oth_post td {position: relative;height: 4.4rem;padding: 0.7rem 0.9rem;border-bottom: 0.9px solid var(--tbl-bdr);font-size: 1.6rem;font-weight: 400;color: var(--dark);letter-spacing: -0.05em;background: #FFFFFF;}
.oth_post th {font-weight: normal;background: var(--tbl-th);}
.oth_post i {margin-left: 1.3rem;font-size: 1.3rem;color: var(--dark);}
.oth_post a.ellipsis {display: block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}

/* cmnt_area */
.cmnt_area {margin-top: 4.4rem;border-top: 0.9px solid #000;}
.cmnt_write {padding: 1.1rem 0.9rem;border-bottom: 0.9px solid #e1e1e1;}
.cmnt_area .cmnt_title {overflow: hidden;}
.cmnt_area .cmnt_title .cmnt_num {float: left;font-size: 1.6rem;font-weight: 500;line-height: 1;}
.cmnt_area .cmnt_title .cmnt_num strong {font-weight: 500;color: #d22234;}
.cmnt_area .cmnt_title p {float: right;font-size: 1.4rem;font-weight: 300;line-height: 1;}
.cmnt_area .write_box {display: flex;clear: both;}
.cmnt_area .cmnt_title + .write_box {padding-top: 1.1rem;}
.cmnt_area .info + .write_box {padding-top: 0.6rem;}
.cmnt_area .write_box .btn.cmnt {width: 10rem;height: 7.8rem;margin-left: 1.1rem;border-radius: 0.6rem;font-size: 1.6rem;font-weight: 500;text-align: center;}
.cmnt_area textarea {width: 100%;min-height: 0;height: 7.8rem;border: 0.9px solid #ccc;font-size: 1.4rem;}

.cmnt_area .cmnt_list > ul > li {border-bottom: 0.9px solid #e1e1e1;}
.cmnt_area .cmnt_list .info span {font-size: 1.6rem;font-weight: 300;color: #969696;}
.cmnt_area .cmnt_list .info span + span {margin-left: 1.1rem;}
.cmnt_area .cmnt_list .info .user {font-weight: 600;color: #000;}
.cmnt_area .cmnt_list .info .user i {margin-right: 0.3rem;}
.cmnt_area .cmnt_list .reply_box {padding: 1.1rem 0.9rem;}
.cmnt_area .cmnt_list .info {margin-bottom: 0.6rem;}
.cmnt_area .cmnt_list .btn_area {float: right;position: relative;top: -0.3rem;margin-top: 0;}
.cmnt_area .cmnt_list p {clear: both;font-size: 1.6rem;}
.cmnt_area .btn.reply {height: auto;margin-top: 1.1rem;margin-bottom: 0.6rem;padding: 0;border: 0;font-size: 1.6rem;line-height: 1;text-align: left;background: none;}
.cmnt_area .btn.reply strong {padding-left: 0.3rem;color: #d22234;}
.cmnt_area .btn.reply::after {content: '';display: inline-block;width: 1rem;height: 0.8rem;margin-left: 0.6rem;background: url(../images/mobile/i_select_arrow.png) no-repeat center center / 1rem;vertical-align: 0.1rem;transition: all 0.1s;}
.cmnt_area .btn.reply.on::after {transform: rotate(180deg);}

.cmnt_area .re_reply {display: none;}
.cmnt_area .re_reply li {padding: 1.1rem 0.9rem;padding-left: 3.7rem;border-bottom: 0.9px solid #e1e1e1;background: url(../images/mobile/i_reply.png) no-repeat left 2rem top 1.6rem;}
.cmnt_area .re_reply li:last-of-type {border-bottom: 0;}
.cmnt_area .re_reply .write {padding-left: 2rem;padding-bottom: 0;border-bottom: 0;border-top: 0.9px solid #e1e1e1;background: none;}
.cmnt_area .re_reply .write:first-child:last-child {padding-bottom: 1.7rem;}
.cmnt_area .no_data {padding: 2.2rem;font-size: 1.6rem;text-align: center;}

/* board gallery*/
.gallery_wrap {position: relative;}
.gallery_wrap .thum_list {display: flex;flex-wrap: wrap;justify-content: space-between;}
.gallery_wrap .thum_list li {overflow: hidden;width: 49%;margin-bottom: 1.7rem;border: 0.9px solid var(--bdr);border-radius: 0.6rem;}
.gallery_wrap .thum_list li > a {display: block;}
.gallery_wrap .thum_img {overflow: hidden;position: relative;width: 100%;height: 22.2rem;}
.gallery_wrap .thum_img img {width: 100%;height: 100%;object-fit: cover;}
.gallery_wrap .thum_txt {min-height: 6.2rem;padding: 1.1rem;background: #ffffff;}
.gallery_wrap .thum_txt .tit {margin-bottom: 0.8rem;font-size: 1.7rem;font-weight: 500;line-height: 1;color: var(--dark);overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.gallery_wrap .thum_txt .date {font-size: 1.6rem;font-weight: 400;line-height: 1;color: var(--gray);}
@media screen and (max-width: 432px) {
    .gallery_wrap .thum_list li {width: 100%;margin-bottom: 1.1rem;}
    .gallery_wrap .thum_list li:last-of-type {margin-bottom: 0;}
}


/* faq */
.faq_list {position: relative;}
.faq_list dl {background: #FFFFFF;}
.faq_list dl:last-of-type {border-bottom: 0.9px solid var(--black);}
.faq_list dl dt {position: relative;min-height: 5.7rem;border-top: 0.9px solid var(--black);}
.faq_list dl dt a {display: flex;align-items: center;position: relative;width: 100%;height: 100%;padding: 1.7rem 4.4rem 1.7rem 5.6rem;font-size: 1.8rem;font-weight: bold;line-height: 1.3;color: var(--dark);}
.faq_list dl dt span {display: block;position: absolute;left: 2.2rem;top: 13.5px;font-size: 1.8rem;font-weight: 900;color: var(--dark);}
.faq_list dl dt i {position: absolute;right: 1.1rem;top: 11.7px;font-size: 2.8rem;color: var(--black);transition: all 0.2s;}
.faq_list dl dl.on dt, .faq_list dl dt:hover {background: var(--basic-bg);}
.faq_list dl dl.on dt i {transform: rotate(180deg);}
.faq_list dl dd {display: none;position: relative;padding: 1.7rem 4.4rem 1.7rem 5.6rem;border-top: 0.9px solid var(--black);font-size: 1.8rem;font-weight: 400;line-height: 1.3;color: var(--dark);}
.faq_list dl dd span {display: block;position: absolute;left: 2.2rem;font-size: 1.8rem;font-weight: 900;color: var(--blue);}


/** search **/
.sch_area {padding: 1.1rem;border: 0.9px solid var(--tbl-bdr);border-radius: 0.6rem;background: var(--basic-bg);}
.sch_area .btn_search, .sch_area .btn.xi-search {border: 0;color: #FFFFFF;background: var(--gray);}
.sch_area .btn_search:hover,
.sch_area .btn_search:focus {background: var(--btn-gray-hover);}
.sch_area .btn_search i {margin-right: 4.5px;}
.sch_area .btn.xi-search {width: 4rem;height: 4rem;font-size: 2.2rem;}
.sch_area li + li {margin-top: 1.1rem;}
.sch_area label {display: block;margin-bottom: 0.6rem;padding-left: 1.1rem;font-size: 1.6rem;background: url(../images/icon/bg_search_label.png) no-repeat 0 50%;}
.sch_area .form_group {display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;}
.sch_area .form_group .form {min-width: 0;width: 100%;}
.sch_area .form_group .form:first-child:nth-last-child(1) {width: 100%;}
.sch_area .form_group .form:first-child:nth-last-child(2), .sch_area .form_group .form:first-child:nth-last-child(2) ~ .form {width: 49%;}
.sch_area .form_group .form:first-child:nth-last-child(3), .sch_area .form_group .form:first-child:nth-last-child(3) ~ .form {width: 32%;}
.sch_area .form_group .form.sch_input {width: calc(100% - 4.8rem) !important;}
.sch_area .form_group .calendar_input {width: 47%;}
.sch_area .btn_area {margin-top: 1.1rem;}


/** paging **/
.paging_wrap {position: relative;margin-top: 2.2rem;}
.paging {font-size: 0;text-align: center;}
.paging li {display: inline-block;position: relative;min-width: 3.3rem;height: 3.3rem;border: 0.9px solid var(--bdr);border-left: 0;font-size: 1.4rem;line-height: 3.1rem;color: var(--dark);background-color: #FFFFFF;vertical-align: middle;cursor: pointer;}
.paging li a {display: block;height: 100%;padding: 0 0.6rem;}
.paging li:first-child {border-left: 0.9px solid var(--bdr);border-radius: 0.6rem 0 0 0.6rem;}
.paging li:last-child {border-radius: 0 0.6rem 0.6rem 0;}
.paging li.btn_page {font-size: 0;color: var(--black);}
.paging li.btn_page a::before,
.paging li.btn_page a::after {font-family: 'xeicon';font-size: 1.3rem;}
.paging li.btn_page a::after {margin-left: -0.9rem;}
.paging li.first a::before,
.paging li.first a::after,
.paging li.prev a::before {content: '\e93d';}
.paging li.next a::before,
.paging li.last a::after,
.paging li.last a::before {content: '\e940';}
.paging li:hover,
.paging .current {border-color: var(--blue);color: #FFFFFF;background-color: var(--blue);}

/*** tab ***/
.tab.basic > .tab_menu {display: flex;flex-wrap: wrap;}
.tab.basic > .tab_menu li {width: 50%;height: 5.6rem;border: 0.9px solid var(--bdr);border-left: 0;font-size: 1.7rem;color: #696969;text-align: center;letter-spacing: -0.1rem;background: var(--basic-bg);}
/* 메뉴가 3개일땐 1열 3행 */
.tab.basic > .tab_menu li:first-child:nth-last-child(3), .tab.basic > .tab_menu li:first-child:nth-last-child(3) ~ li {width: 33.333%;}
/* 메뉴가 2개 or 4개 이상일땐 1열 2행 */
.tab.basic > .tab_menu li:first-child:nth-last-child(n+4), .tab.basic > .tab_menu li:first-child:nth-last-child(n+4) ~ li {width: 50%;}
.tab.basic > .tab_menu li:first-child:nth-last-child(n+4) ~ li:nth-of-type(n+3) {border-top: 0;}
.tab.basic > .tab_menu li:first-child:nth-last-child(n+4) ~ li:nth-of-type(odd) {border-left: 0.9px solid var(--bdr);}
.tab.basic > .tab_menu li:first-child {border-left: 0.9px solid var(--bdr);}
.tab.basic > .tab_menu li a {display: flex;justify-content: center;align-items: center;height: 100%;padding: 0.6rem;}
.tab.basic > .tab_menu li:has(.on) {border: 0;color: #fff;background: var(--blue);}
.tab.basic > .tab_cont {margin-top: 3.3rem;font-size: 1.7rem;}

/* line */
.tab.line {position: relative;}
.tab.line::after {content: '';display: block;position: absolute;left: 0;top: 4.4rem;right: 0;width: 100%;height: 0.2rem;background: var(--bdr);}
.tab.line > .tab_menu {display: flex;overflow-x: auto;}
.tab.line > .tab_menu li {position: relative;min-width: 8.9rem;}
.tab.line > .tab_menu li:has(.on) {font-weight: 500;}
.tab.line > .tab_menu li:has(.on)::before {content: '';position: absolute;bottom: 0;width: 100%;height: 0.2rem;background: var(--black);z-index: 999;}
.tab.line > .tab_menu li a {display: block;padding: 1.1rem 1.7rem;font-size: 1.7rem;color: var(--dark);}
.tab.line > .tab_cont {margin-top: 3.3rem;font-size: 1.7rem;}

/* first tab */
.tab.f_tab > .tab_menu {position: relative;height: 5rem;font-size: 1.9rem;}
.tab.f_tab > .tab_menu::after {content: '';display: block;clear: both;}
.tab.f_tab > .tab_menu li {float: left;width: 13.3rem;height: 100%;border: 0.9px solid #ddd;border-left: 0;line-height: 5rem;text-align: center;}
.tab.f_tab > .tab_menu li:first-child {border-left: 0.9px solid #ddd;}
.tab.f_tab > .tab_menu li:has(.on) {border-color: #437dea;color: #FFFFFF;background: #437dea;}
.tab.f_tab > .tab_menu li a {display: block;}
/* second tab */
.tab.s_tab > .tab_menu {padding: 1.7rem 3.9rem;border-bottom: 0.9px solid #ddd;font-size: 1.7rem;background: #f3f4f6;}
.tab.s_tab > .tab_menu::after {content: '';display: block;clear: both;}
.tab.s_tab > .tab_menu li {float: left;position: relative;margin-right: 2.4rem;padding-left: 1.1rem;}
.tab.s_tab > .tab_menu li::after {content: '';position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 0.4rem;height: 0.4rem;background: #797979;}
.tab.s_tab > .tab_menu li:has(.on) {color: #2b6fcc;text-decoration: underline;text-underline-position: under;}
.tab.s_tab > .tab_menu li:has(.on)::after {background: #2b6fcc;}
.tab.s_tab > .tab_menu li a {display: block;}
/* third tab */
.tab.t_tab > .tab_menu {height: 5rem;padding: 0 5rem;border-bottom: 0.9px solid #ddd;font-size: 1.7rem;line-height: 5rem;color: #454545;}
.tab.t_tab > .tab_menu::after {content: '';display: block;clear: both;}
.tab.t_tab > .tab_menu li {float: left;position: relative;}
.tab.t_tab > .tab_menu li:nth-child(n+2) {margin-left: 2.7rem;}
.tab.t_tab > .tab_menu li:nth-child(n+2)::after {content: '';position: absolute;left: -1.4rem;top: 50%;transform: translateY(-50%);width: 0.9px;height: 1.7rem;background: #ddd;}
.tab.t_tab > .tab_menu li:has(.on) {font-weight: 700;}
.tab.t_tab > .tab_menu li a {display: block;}
/* balloon tab */
.tab.balloon > .tab_menu::after {content: '';display: block;clear: both;}
.tab.balloon > .tab_menu li {position: relative;min-width: 11.1rem;height: 3.9rem;border: 0.9px solid var(--bdr);border-radius: 2rem;line-height: 3.7rem;}
.tab.balloon > .tab_menu li:not(:last-child) {margin-right: 1.1rem;}
.tab.balloon > .tab_menu li:has(.on) {border: 0;line-height: 3.9rem;color: #FFFFFF;background: var(--blue);}
.tab.balloon > .tab_menu li:has(.on)::after {content: '';display: block;position: absolute;left: 50%;bottom: -0.6rem;width: 0;height: 0;margin-left: -0.6rem;border-left: 0.6rem solid transparent;border-right: 0.6rem solid transparent;border-top: 0.6rem solid var(--blue);}
.tab.balloon > .tab_menu li a {padding: 0 1.7rem;}


/** popup **/
.popup_bg {display: none;position: fixed;left: 0;top: 0;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.5);z-index: 5000;}
/* layer popup */
.layer_pop {visibility: hidden;overflow: hidden;position: absolute;left: 2.5%;width: 95%;border-radius: 0 0 0.6rem 0.6rem;z-index: -1; top: 50%; transform: translateY(-50%);}
.layer_pop * {visibility: hidden;}
.layer_pop.on * {visibility: visible;}
.layer_pop .pop_header {display: flex;justify-content: space-between;align-items: center;position: relative;height: 5.6rem;padding: 1.1rem 1.7rem;border-bottom: 0.9px solid var(--bdr);border-radius: 0.6rem 0.6rem 0 0;background: #FFFFFF;}
.layer_pop .pop_header h2 {font-size: 1.9rem;font-weight: 500;}
.layer_pop .pop_close {height: 2.7rem;margin: 0;border: 0;font-size: 0;background: transparent;}
.layer_pop .pop_close i::before {font-size: 2.7rem;color: var(--gray);}
.layer_pop .pop_content {position: relative;padding: 1.7rem;font-size: 1.6rem;background: #FFFFFF;}
.layer_pop .pop_footer {padding: 1.1rem 1.7rem;text-align: right;background: var(--basic-bg);}
/* main popup */
.main_pop {overflow: hidden;position: absolute;left: 2.5%;top: 9px;width: 95%;border-radius: 0.6rem;box-shadow: 0rem 0.6rem 1.1rem rgba(0, 0, 0, 0.2);z-index: 9999;}
.main_pop .pop_header {display: flex;justify-content: space-between;align-items: center;position: relative;padding: 1.1rem 1.7rem;min-height: 5.6rem;height: auto;line-height: 1.3;color: #fff;background: var(--blue);}
.main_pop .pop_header h2 {font-size: 1.9rem;font-weight: 500;}
.main_pop .pop_close {height: 2.7rem;margin: 0;border: 0;font-size: 0;background: transparent;}
.main_pop .pop_close i::before {font-size: 2.7rem;color: #fff;}
.main_pop .pop_content {overflow-y: auto;position: relative;max-height: 22.2rem;padding: 1.7rem;font-size: 1.6rem;background: #FFFFFF;}
.main_pop .pop_content .mCSB_scrollTools_vertical, .main_pop .pop_content .mCSB_dragger_bar, .main_pop .pop_content .mCSB_draggerRail {width: 0.6rem !important;margin: 0 !important;}
.main_pop .pop_content .mCSB_dragger_bar {background-color: #6B6B6B !important;}
.main_pop .pop_content .mCSB_scrollTools .mCSB_draggerRail {background-color: #ECECEC !important;}
.main_pop .pop_content .mCSB_container {margin-right: 1.7rem !important;}
.main_pop .pop_content .mCSB_container.mCS_y_hidden {margin-right: 0 !important;}
.main_pop .pop_footer {display: flex;justify-content: space-between;align-items: center;padding: 1.1rem 1.7rem;font-size: 1.6rem;background: var(--basic-bg);}


/** error **/
.error_wrap {position: relative;width: 100%;margin: 0 auto;}
.error_wrap h1 {position: relative;margin-bottom: 2.2rem;border-bottom: 0.9px solid #e5e5e5;font-size: 2.8rem;}
.error_wrap .error_box {display: flex;justify-content: center;position: relative;}
.error_wrap .error_info {position: relative;padding-top: 15.6rem;background: url(../images/mobile/bg_error404b.jpg) no-repeat center top / 14.4rem;}
.error_wrap .error_info .tit {font-size: 1.7rem;color: #000;letter-spacing: -0.05em;text-align: center;}
.error_wrap .error_info .tit strong {display: block;font-size: 2.2rem;color: #333;}
.error_wrap .error_info .txt {margin-top: 1.1rem;font-size: 1.6rem;line-height: 1.5;color: #808080;text-align: center;}
.error_wrap .error_btns {margin-top: 2.2rem;}
.error_wrap .error_btns a {display: inline-block;width: 15.6rem;height: 4.4rem;font-size: 1.4rem;line-height: 4.2rem;}
.error_wrap .error_btns a + a {margin-left: 0.6rem;}
.error_btns .btn_main {border: 0.9px solid transparent;border-color: #000;color: #000;background-color: #fff;}
.error_btns .btn_prev {color: #fff;background-color: #282828;}


/** membership **/
/* login */
.login_wrap {display: flex;justify-content: center;align-items: center;width: 100%;padding: 5.6rem 2.2rem;}
.login_box {position: relative;width: 100%;max-width: 62.2rem;margin: 0 auto;}
.login_box h1.logo {width: 100%;margin-bottom: 3.3rem;padding-bottom: 0;text-align: center;}
.login_box h1.logo img {max-width: 16.7rem;}
.login_box .input_box input {width: 100%;height: 4.8rem;padding: 0 1.7rem;padding-right: 3.7rem;font-size: 1.6rem;line-height: 4.8rem;}
.login_box .input_box > ul > li {position: relative;}
.login_box .input_box > ul > li + li {margin-top: 1.1rem;}
.login_box .input_box label {display: block;margin-bottom: 0.9rem;font-size: 1.6rem;font-weight: 500;color: #404040;}
.login_box .input_box .btn_del {opacity: 0;position: absolute;right: 1.1rem;top: 4.8rem;width: 1.9rem;height: 1.9rem;font-size: 0;border: 0;background: url(../images/icon/i_input_del.png) no-repeat center;z-index: 99;}
.login_box .input_box .btn_del.on {opacity: 1;}
.login_box .chk {margin-top: 1.7rem;}
.login_box .chk > span label {font-size: 1.6rem;}
.login_box .chk .cbx label::before {width: 1.7rem;height: 1.7rem;line-height: 1.4rem;}
.login_box .chk .cbx input:checked + label::before {font-size: 1.4rem;}
.login_box .links {margin-top: 2.2rem;text-align: center;}
.login_box .links li {display: inline-block;position: relative;}
.login_box .links li + li {margin-left: 2.7rem;}
.login_box .links li + li::before {content: '';position: absolute;left: -1.3rem;top: 0.8rem;width: 0.1rem;height: 1.7rem;background: #cecece;}
.login_box .links a {display: inline-block;padding: 0.6rem;font-size: 1.6rem;font-weight: 400;}
.login_box .links a:hover {text-decoration: underline;}
.login_box .links a.blue {color: var(--blue);}
.login_box .btn_area {margin-top: 2.2rem;}
.login_wrap .error_txt {margin-top: 0.7rem;line-height: 1.5;color: #e83667;}
.member_sns {margin-top: 4.4rem;}
.member_sns .tit {display: block;position: relative;text-align: center;}
.member_sns .tit::before {content: "";opacity: .84;display: block;position: absolute;left: 0;top: 50%;width: 100%;height: 0.1rem;margin-top: -0.1rem;border: dotted #dadada;border-width: 0.2rem 0 0;z-index: 1;}
.member_sns .tit span {position: relative;display: inline-block;padding: 0.6rem;font-size: 1.7rem;font-weight: 400;color: gray;background: #fff;z-index: 2;}
.member_sns .btn_area {display: flex;justify-content: center;margin: 1.8rem 0;}
.member_sns a {display: inline-block;width: 4rem;height: 4rem;margin: 0 1.1rem;font-size: 0;vertical-align: top;}
.member_sns .kakao {background: url(../images/icon/img_kakaotalk.png) no-repeat center / 4rem;}
.member_sns .naver {background: url(../images/icon/img_naver.png) no-repeat center / 4rem;}
.btn_login {display: block;width: 100%;height: 5.6rem;font-size: 1.8rem;font-weight: 500;line-height: 5.4rem;}
/* id/pwd 찾기 css 추가 */
.login_box.find_box {display: flex;flex-wrap: wrap;justify-content: space-between;max-width: 100rem;}
.login_box.find_box .box {width: 100%;max-width: 47.8rem;}
.login_box.find_box .box + .box {margin-top: 3.9rem;padding-top: 3.9rem;border-top: dotted #dadada;border-width: 0.2rem 0 0;}
.login_box.find_box .box > b {display: block;margin-bottom: 2.8rem;font-size: 2.2rem;font-weight: 600;line-height: 1.25;color: #202020;letter-spacing: -0.01em;text-align: center;}

/* join 공통 */
.join {width: 100%;max-width: 62.2rem;margin: 0 auto;padding: 0 2.2rem;}
.join .page_tit {display: block;margin-bottom: 3.3rem;font-size: 2.2rem;font-weight: 600;line-height: 1.25;color: #202020;letter-spacing: -0.01em;text-align: center;}
.join .page_tit span {display: block;margin-top: 0.8rem;font-size: 1.7rem;font-weight: 400;color: #606060;}
.btn_join {display: block;width: 100%;height: 5.6rem;font-size: 1.8rem;font-weight: 500;line-height: 5.4rem;}

/* 1. 회원가입안내 join.info */
.member_sns {margin-top: 2.2rem;}
.member_sns .tit {display: block;position: relative;text-align: center;}
.member_sns .tit::before {content: "";opacity: .84;display: block;position: absolute;left: 0;top: 50%;width: 100%;height: 0.1rem;margin-top: -0.1rem;border: dotted #dadada;border-width: 0.2rem 0 0;z-index: 1;}
.member_sns .tit span {position: relative;display: inline-block;padding: 0.6rem;font-size: 1.7rem;font-weight: 400;color: gray;background: #fff;z-index: 2;}
.member_sns .btn_area {display: flex;justify-content: center;margin: 1.8rem 0;}
.member_sns a {display: inline-block;width: 4rem;height: 4rem;margin: 0 1.1rem;font-size: 0;vertical-align: top;}
.member_sns .kakao {background: url(../images/icon/img_kakaotalk.png) no-repeat center / 4rem;}
.member_sns .naver {background: url(../images/icon/img_naver.png) no-repeat center / 4rem;}

/* 2. 본인인증 join.sign */
.join.sign {text-align: center;}
.join.sign .btns {text-align: center;}
.join.sign .btns li {display: inline-block;}
.join.sign .btns li + li {margin-left: 2.2rem;}
.join.sign .btns a {display: block;}
.join.sign .btns a img {overflow: hidden;width: 7.8rem;border-radius: 20%;}
.join.sign .btns a p {margin-top: 0.8rem;font-size: 1.6rem;font-weight: 400;line-height: 1.3;color: #606060;letter-spacing: -0.01em;}
.join.sign .sub_txt {display: inline-block;position: relative;margin-top: 3.1rem;padding-left: 2rem;font-size: 1.4rem;font-weight: 400;line-height: 1.5;color: var(--gray);text-align: left;}
.join.sign .sub_txt i {position: absolute;left: 0;top: 0.4rem;}
.join.sign .sub_txt br {display: none;}

/* 3. 약관동의 */
.join.agreement .box {padding: 2.2rem 1.7rem;border-radius: 1.3rem;text-align: left;background: #f8f8f8;}
.join.agreement .chk_all .cbx {margin: 0;}
.join.agreement .chk_all .cbx label {font-size: 1.7rem;font-weight: 500;line-height: 1.9rem;}
.join.agreement .chk_all .cbx label::before {width: 1.7rem;height: 1.7rem;line-height: 1.7rem;}
.join.agreement .list {margin-top: 2.2rem;padding-top: 2.2rem;border-top: 0.2rem dotted #dadada;}
.join.agreement .list li + li {margin-top: 1.7rem;}
.join.agreement .list .cbx {margin: 0;}
.join.agreement .list .cbx label {display: block;font-size: 1.6rem;font-weight: 400;line-height: 1.9rem;color: #777;}
.join.agreement .list .cbx label b {margin-right: 0.3rem;font-size: 1.4rem;font-weight: 500;color: var(--blue);}
.join.agreement .list .cbx label b.gray {color: var(--gray);}
.join.agreement .list .cbx label::before {width: 1.7rem;height: 1.7rem;line-height: 1.7rem;background: #fff;}
.join.agreement .list .cbx input:checked + label::before {content: '\e928';background: var(--chk-bg);}
.join.agreement .list li {position: relative;}
.join.agreement .list li .btn_more, .join.agreement .list li .btn_more2 {opacity: 0.7;display: block;position: absolute;right: 0;top: -0.1rem;width: 2.2rem;height: 2.2rem;font-size: 0;background: url(../images/icon/i_faq_off.png) no-repeat center / 1.1rem;transform: rotateZ(-90deg);}
.join.agreement .list li .btn_more2 {transform: rotate(0deg);}
.join.agreement .list li .btn_more2.on {transform: rotate(180deg);}
.join.agreement .list li .cont {display: none;margin-top: 0.6rem;padding-left: 2.2rem;font-size: 1.4rem;}
.term_box {overflow-y: auto;max-height: 70vh;}
.term_box * {font-size: 1.4rem;line-height: 1.3;}
.term_box strong {display: block;margin-bottom: 0.6rem;font-size: 1.6rem;font-weight: 500;}
.term_box .tit {margin-bottom: 1.1rem;font-size: 1.7rem;font-weight: 600;}
.term_box .tit + ul > li + li {margin-top: 1.7rem !important;}
.term_box > ul > li + li {margin-top: 2.2rem !important;}
.term_box .tdepth {padding-left: 1.7rem;padding-top: 0.6rem;font-size: 1.6rem;}
.term_box p {margin-top: 1.1rem !important;}

/* 4. 정보입력 */
.join.form .error_txt {margin-top: 0.3rem;font-size: 1.3rem;color: #ed2828 !important;}
.join.form .form_box > ul > li {display: flex;flex-wrap: wrap;}
.join.form .form_box > ul > li .tit {display: block;width: 100%;margin-bottom: 0.9rem;font-size: 1.6rem;font-weight: 500;color: #404040;}
.join.form .form_box > ul > li .tit.required::after {content: '*';margin-left: 0.3rem;font-size: 1.6rem;font-weight: 400;color: var(--red);}
.join.form .form_box > ul > li + li {margin-top: 1.3rem;}
.join.form .form_box input, .join.form .form_box select {width: 100%;height: 4.8rem;padding: 0 1.7rem;font-size: 1.6rem;line-height: 4.8rem;}
.join.form .form_box [disabled] {opacity: 1;color: var(--dark);background-color: #f6f6f6;}
.join.form .form_box .form_group {display: flex;width: 100%;}
.join.form .form_box .form_group input {width: calc(100% - 9.4rem);}
.join.form .form_box .btn {width: 8.9rem;height: 4.8rem;margin-left: 0.6rem;font-size: 1.6rem;line-height: 4.6rem;text-align: center;}
.join.form .form_box .form_group.space {justify-content: space-between;align-items: center;font-size: 1.3rem;}
.join.form .form_box .form_group.space select {min-width: 11.1rem;}
.join.form .form_box .phone input, .join.form .form_box .phone select, .join.form .form_box .email input, .join.form .form_box .email select {max-width: 100%;}
.join.form .chk.toggle {display: flex;width: 100%;}
.join.form .chk.toggle input {left: 0;}
.join.form .chk.toggle .radio {position: relative;width: 50%;height: 5.6rem;margin: 0;text-align: center;z-index: 999;}
.join.form .chk.toggle .radio label {position: absolute;left: 0;top: 0;width: 100%;height: 4.8rem;border: 0.1rem solid var(--bdr);font-size: 1.6rem;font-weight: 500;line-height: 4.6rem;}
.join.form .chk.toggle .radio label::before, .join.form .chk.toggle .radio label::after {display: none;}
.join.form .chk.toggle .radio:first-of-type label {border-right: 0;border-radius: 0.9rem 0 0 0.9rem;}
.join.form .chk.toggle .radio:last-of-type label {border-left: 0;border-radius: 0 0.9rem 0.9rem 0;}
.join.form .chk.toggle .radio input:checked + label {color: #63a1ff;}
.join.form .chk.toggle .radio input:checked + label, .join.form .chk.toggle .radio input:checked + label:hover {border: 0.1rem solid #63a1ff !important;background: #F8FBFF;}
.join.form .btn_area {margin-top: 4.4rem;}

/* 5. 가입완료 finish */
.join.finish p {position: relative;font-size: 1.7rem;font-weight: 500;line-height: 1.4;color: #202020;letter-spacing: -0rem;text-align: center;}
.join.finish img {display: block;max-width: 16.7rem;margin: 0 auto 3.3rem;}
.join.finish .btn_area {margin-top: 3.3rem;margin-bottom: 0;}
.join.finish .btn_area .btn_join {display: inline-block;width: 15.6rem;height: 5rem;margin: 0 0.6rem;font-size: 1.7rem;line-height: 4.9rem;}






/**/